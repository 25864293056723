import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import '../App.css';

export default function SectionTitle(props) {
    
    const useStyles = makeStyles((theme) => ({
        portada: {
            width: "100%",
            height: "90vh",
            objectFit: "cover",
            overflow: "hidden",
        },
        tiles: {
            backgroundColor: `#${props.color}`,
            width: "100%",
            height: "1.5rem"
        },
        title: {
            textAlign: "center"
        }
    }));
    const classes = useStyles();

    return (
        <Grid container justify="center" alignItems="center" item xs={12} className="contenedor">
            <Grid item md={4} xs={2} className="hide">
                <div className={classes.tiles}></div>
            </Grid>
            <Grid item md={4} xs={12}>
                <Typography  variant="h2" className={classes.title}>
                    <strong>{props.title}</strong>
                </Typography>
            </Grid>
            <Grid item md={4} xs={2} className="hide">
                <div className={classes.tiles}></div>
            </Grid>
        </Grid>
    );
}