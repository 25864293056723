import { makeStyles, withStyles, fade } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SectionTitle from "./SectionTitle"
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import '../App.css';

export default function Contact(props) {
    
    const useStyles = makeStyles((theme) => ({
        text: {
            color: "white"
        },
        label: {
            color: "white"
        },
        margin: {
            width: "100%",
            margin: theme.spacing(1),
        },
        buttonDiv: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem"
        },
        contactData: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }
    }));
    const classes = useStyles();

    const BootstrapInput = withStyles((theme) => ({
        root: {
          'label + &': {
            marginTop: theme.spacing(3),
          },
        },
        input: {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: theme.palette.common.white,
          border: '1px solid #ced4da',
          fontSize: 16,
          width: '100%',
          padding: '10px 12px',
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          // Use the system font instead of the default Roboto font.
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
        },
      }))(InputBase);

      const ColorButton = withStyles((theme) => ({
        root: {
          color: "white",
          backgroundColor: "#03A3B1",
          borderRadius: "1.5rem",
          width: "auto",
          '&:hover': {
            backgroundColor:"#03A3B1",
          },
        },
      }))(Button);

    return (
        <div className="container">
        <br />
        <br />
        <br id="contact"/>
        <SectionTitle title="Contacto" color="03A3B1"/>
        <br />
        <Grid container justify="center" alignItems="center" item md={12}>
        <Grid container justify="center" alignItems="center" item md={10} spacing={3}>
            <Grid item md>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.444410195041!2d-106.4304172850285!3d23.226909884849967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869f5364c73c72f1%3A0xf1a7bc3a20ba6e82!2sHotel%20Suites%20Venezia!5e0!3m2!1ses-419!2smx!4v1623367382484!5m2!1ses-419!2smx" width="100%" height="800" loading="lazy"></iframe>
            </Grid>
            <Grid item md className={classes.contactData}>
                    <Grid item md className="contain rmb-2">
                        <FormControl className={classes.margin}>
                            <Typography  variant="h4" className={classes.label}>Nombre</Typography>
                            <BootstrapInput placeholder="John Doe" id="bootstrap-input" />
                        </FormControl>
                        <FormControl className={classes.margin}>
                            <Typography  variant="h4" className={classes.label}>Correo electrónico</Typography>
                            <BootstrapInput placeholder="john.doe@ejemplo.com" id="bootstrap-input" />
                        </FormControl>
                        <FormControl className={classes.margin}>
                            <Typography  variant="h4" className={classes.label}>Teléfono</Typography>
                            <BootstrapInput placeholder="+52 669 981 5226" id="bootstrap-input" />
                        </FormControl>
                        <FormControl className={classes.margin}>
                            <Typography  variant="h4" className={classes.label}>Mensaje</Typography>
                            <BootstrapInput placeholder="Mensaje" id="bootstrap-input" />
                        </FormControl>
                        <div className={classes.buttonDiv}>
                            <ColorButton className={classes.margin}>
                                <Typography  variant="h4" className={classes.label} style={{padding: "1rem"}}>Enviar</Typography>
                            </ColorButton>
                        </div>
                    </Grid>
                    <br />
                    <br />
                    <Grid item md className="contain" >
                        <Typography  variant="h4" className={classes.label}>Visítanos en:</Typography>
                        <Typography  variant="h4" className={classes.label}>Av. del Mar 616, Tellería, 82017.</Typography>
                        <Typography  variant="h4" className={classes.label}>Mazatlán, Sinaloa.</Typography>
                        <br />
                        <Typography  variant="h4" className={classes.label}>Llámanos</Typography>
                        <Typography  variant="h4" className={classes.label}>+52 669 981 5226</Typography>
                        <Typography  variant="h4" className={classes.label}>+52 669 981 5450</Typography>
                        <Typography  variant="h4" className={classes.label}>+52 669 981 5451</Typography>
                    </Grid>
            </Grid>
            </Grid>
        </Grid>
        <br />
        <br />
        <br />
        </div>
    );
}