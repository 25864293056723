import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ServiceItem from "./ServiceItem"
import SectionTitle from "./SectionTitle"

export default function Services(props) {
    
    const useStyles = makeStyles((theme) => ({
        
    }));
    const classes = useStyles();

    return (
        <>
        <br id="services"/>
        <br />
        <br />
        <SectionTitle title="Servicios" color="D8268A"/>
        <br />
        <Grid container justify="space-evenly" alignItems="center" item md={12}>
        <Grid container justify="space-evenly" alignItems="flex-end" item md={10}>
            <Grid item md>
                <ServiceItem title="Wi-Fi" iconName="wifi"/>
            </Grid>
            <Grid item md>
                <ServiceItem title="A / A" iconName="ac_unit" />
            </Grid>
            <Grid item md>
                <ServiceItem title="Alberca" iconName="pool" />
            </Grid>
            <Grid item md>
                <ServiceItem title="TV" iconName="tv" />
            </Grid>
            <Grid item md>
                <ServiceItem title="Estacionamiento" iconName="drive_eta" />
            </Grid>
        </Grid>
        </Grid>
        <br />
        <br />
        <br />
        </>
    );
}