import NavBar from "../components/NavBar"
import Portada from "../components/Portada"
import Rooms from "../components/Rooms"
import Services from '../components/Services';
import CustomFloatingButtons from '../components/CustomFloatingButtons';
import AboutUs from '../components/AboutUs';
import Gallery from '../components/Gallery';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

export default function App() {
  return (
      <>
        <NavBar />
        <Portada />
        <Rooms />
        <Gallery />
        <Services />
        <Contact />
        <AboutUs />
        <Footer />
        <CustomFloatingButtons />
        </>
    );
        
}
