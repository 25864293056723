import React from 'react';
import SectionTitle from "./SectionTitle"
import Carousel, { arrowsPlugin, Dots, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../App.css';

class Gallery extends React.Component {

    constructor() {
      super()
      this.state = {
        value: 0,
        slides: [

            (<img className="gallery" src="2.jpg" />),
            (<img className="gallery" src="3.jpg" />),
            (<img className="gallery" src="5.jpeg" />),
            (<img className="gallery" src="7.jpeg" />),
            (<img className="gallery" src="8.jpeg" />),
            (<img className="gallery" src="6.jpeg" />),
            (<img className="gallery" src="13.jpeg" />),
            (<img className="gallery" src="14.jpeg" />),
            (<img className="gallery" src="15.jpeg" />),
            (<img className="gallery" src="16.jpeg" />),
            (<img className="gallery" src="18.jpeg" />),
            (<img className="gallery" src="19.jpeg" />),
            (<img className="gallery" src="20.jpeg" />),
            (<img className="gallery" src="21.jpeg" />),
            (<img className="gallery" src="22.jpeg" />),
          ],
          thumbnails: [
            (<img className="galleryThumbnail" src="2.jpg" />),
            (<img className="galleryThumbnail" src="3.jpg" />),
            (<img className="galleryThumbnail" src="5.jpeg" />),
            (<img className="galleryThumbnail" src="7.jpeg" />),
            (<img className="galleryThumbnail" src="8.jpeg" />),
            (<img className="galleryThumbnail" src="6.jpeg" />),
            (<img className="galleryThumbnail" src="13.jpeg" />),
            (<img className="galleryThumbnail" src="14.jpeg" />),
            (<img className="galleryThumbnail" src="15.jpeg" />),
            (<img className="galleryThumbnail" src="16.jpeg" />),
            (<img className="galleryThumbnail" src="18.jpeg" />),
            (<img className="galleryThumbnail" src="19.jpeg" />),
            (<img className="galleryThumbnail" src="20.jpeg" />),
            (<img className="galleryThumbnail" src="21.jpeg" />),
            (<img className="galleryThumbnail" src="22.jpeg" />),
        ],
      }
      this.onchange = this.onchange.bind(this);
    }
  
    onchange(value) {
      this.setState({ value });
    }
  
    render() {
      return (
        <div className="container container-gallery" id="gallery">
            <br />
            <br />
            <SectionTitle title="Galería" color="2684D8"/>
            <br />
            <br />
            <Carousel className="carousel"
                plugins={[
                    {
                    resolve: arrowsPlugin,
                    options: {
                        arrowLeft: <IconButton aria-label="delete">
                        <ChevronLeftIcon style={{fontSize: "3rem"}}/>
                    </IconButton>,
                        arrowRight: <IconButton aria-label="delete">
                        <ChevronRightIcon style={{fontSize: "3rem"}}/>
                    </IconButton>,
                        addArrowClickHandler: true,
                    }
                    },
                    'infinite',
                    {
                        resolve: autoplayPlugin,
                        options: {
                          interval: 3000,
                        }
                    },
                    
                ]}
                animationSpeed={1000}
                
                value={this.state.value}
                slides={this.state.slides}
                onChange={this.onchange}
                />
                <Dots className="hide" thumbnails={this.state.thumbnails} value={this.state.value} onChange={this.onchange} number={this.state.slides.length} />
            <br />
            <br />
            <br />
        </div>
      );
    }
  }

  export default Gallery;