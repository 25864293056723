import { makeStyles } from '@material-ui/core/styles';
import "../App.css";

const useStyles = makeStyles((theme) => ({
  portada: {
    width: "100%",
    minHeight: "90vh",
    backgroundImage: "url('portada-min.jpg')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
}));

export default function Portada() {
  const classes = useStyles();

  return (
    <div className={classes.portada} id="portadaMovil"> 
      <div className="coverTextContainer">
        <a href="https://hotels.cloudbeds.com/reservation/6v3hkG">Reserva ahora</a>
      </div>
    </div>
  );
}