import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from "./SectionTitle"
import '@brainhubeu/react-carousel/lib/style.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default function AboutUs(props) {
    
    const useStyles = makeStyles((theme) => ({
        container: {
            backgroundColor: "#F2F2F2",
            position: "relative"
        },
        title: {
            textAlign: "center"
        }
    }));
    const classes = useStyles();

    return (
        <>
        <div className={classes.container} id="about">
            <br />
            <br />
            <SectionTitle title="Nosotros" color="8AD826"/>
            <br />
            <br />
            <Grid container justify="space-evenly" alignItems="flex-end" item md={12}>
                <Grid item md>
                    
                </Grid>
                <Grid item md={4}>
                    <Typography  variant="h3" className={classes.title}>
                        <i>Suites Venezia</i> está ubicado en el Malecon de Mazatlán. Ofrecemos alojamiento con piscina al aire libre con vista al mar, suites con balcón privado y vista al mar, aparcamiento privado gratuito, jardín y terraza, Wi-Fi gratuito y recepción las 24 horas.</Typography>
                    <br/>
                    <Typography  variant="h3" className={classes.title}>
                        ¡Hablamos tu idioma!
                    </Typography>
                </Grid>
                <Grid item md>
                    
                </Grid>
            
            </Grid>
            
            <br />
            <br />
            <br />
        </div>
        </>
    );
}