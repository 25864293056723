import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

export default function ServiceItem(props) {
    
    const useStyles = makeStyles((theme) => ({
        title: {
            color: "#03A3B1",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "20px"
        },
        iconContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "14rem",
            width: "14rem",
            backgroundColor: "#2684D8",
            borderRadius: "50%",
            textAlign: "center",
            marginBottom: "1rem"
        },
        icon: {
            color: "#FFFFFF",
            fontSize: "8rem"
        }
    }));
    const classes = useStyles();

    return (
        <Grid direction="column" container justify="center" alignItems="center" item xs={12}>
            <Grid item>
                <Typography  variant="h3" className={classes.title}>
                    {props.title}
                </Typography>
            </Grid>
            <Grid item >
                <div className={classes.iconContainer}>
                    <Icon className={classes.icon}>{props.iconName}</Icon>
                </div>
            </Grid>
        </Grid>
    );
}