import React from 'react';
import SectionTitle from "./SectionTitle"
import Carousel, { arrowsPlugin, Dots, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../App.css';
import BorderlessCard from "./BorderlessCard"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class Rooms extends React.Component {

    constructor() {
      super()
      this.state = {
        value: 0,
        slides: [
            (<Grid container class="responsive-flex" item md={12}>
              <Grid item md>
                <img className="portada" src="11.jpeg" alt="portada" />
              </Grid>
              <Grid item md>
                <Typography  variant="h2" className="room-title">
                  <strong>Suite con vista al mar</strong>
                </Typography>
                <br/>
                <Typography  variant="h2" className="room-text">
                  Todas nuestas suites cuentan con dos habitaciones: una recámara principal 
                  con una cama king size, baño completo y vestidor; y una
                  segunda recámara con dos camas individuales y/o literas, un baño completo
                  cuarto de lavado, sala, comedor, cocina y balcón privado con vista al mar.
                </Typography>
              </Grid>
            </Grid>),
            (<Grid container class="responsive-flex" item md={12}>
              <Grid item md>
                <img className="portada" src="1.jpg" alt="portada" />
              </Grid>
              <Grid item md>
                <Typography  variant="h2" className="room-title">
                  <strong>Suite sin vista al mar</strong>
                </Typography>
                <br/>
                <Typography  variant="h2" className="room-text">
                Todas nuestas suites cuentan con dos habitaciones: una recámara principal 
                con una cama king size, baño completo y vestidor; y una
                segunda recámara con dos camas individuales y/o literas, un baño completo
                cuarto de lavado, sala, comedor y cocina.
                </Typography>
              </Grid>
            </Grid>),
            (<Grid container class="responsive-flex" item md={12}>
              <Grid item md>
                <img className="portada" src="10npm.jpeg" alt="portada" />
              </Grid>
              <Grid item md>
                <Typography  variant="h2" className="room-title">
                  <strong>Estándar</strong>
                  </Typography>
                <br/>
                <Typography  variant="h2" className="room-text">
                Habitación con una cama matrimonial, frente a la playa.
                </Typography>
                <br/>
                <Typography  variant="h2" className="room-text">
                Todas las habitaciones cuentan con aire acondicionado, 
                pantalla de 42 pulgadas con cable, agua caliente, 
                inernet inalámbrico, estacionamiento y alberca 
                </Typography>
              </Grid>
            </Grid>),
        ]
      }
      this.onchange = this.onchange.bind(this);
    }
  
    onchange(value) {
      this.setState({ value });
    }
  
    render() {
      return (
        <div id="rooms" className="">
            <br />
            <br />
            <SectionTitle title="Habitaciones" color="8AD826"/>
            <br />
            <br/>
            <Carousel className="carousel"
                plugins={[
                  {
                    resolve: arrowsPlugin,
                    options: {
                        arrowLeft: <IconButton aria-label="delete">
                        <ChevronLeftIcon style={{fontSize: "3rem"}}/>
                    </IconButton>,
                        arrowRight: <IconButton aria-label="delete">
                        <ChevronRightIcon style={{fontSize: "3rem"}}/>
                    </IconButton>,
                        addArrowClickHandler: true,
                    }
                    },
                    'infinite',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                         numberOfSlides: 1
                        }
                    },
                ]}
                breakpoints={{
                  640: {
                    plugins: [
                     {
                       resolve: slidesToShowPlugin,
                       options: {
                        numberOfSlides: 1
                       }
                     },
                     {
                      resolve: arrowsPlugin,
                      options: {
                          arrowLeft: <IconButton aria-label="delete">
                          <ChevronLeftIcon fontSize="large"/>
                      </IconButton>,
                          arrowRight: <IconButton aria-label="delete">
                          <ChevronRightIcon fontSize="large"/>
                      </IconButton>,
                          addArrowClickHandler: true,
                      }
                      },
                   ]
                  },
                }}
                animationSpeed={1000}
                
                value={this.state.value}
                slides={this.state.slides}
                onChange={this.onchange}
                />
            <br />
            <br />
            <br />
        </div>
      );
    }
  }

  export default Rooms;